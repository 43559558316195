// baseAdminHelper.js
import Vue from 'vue';
import deepmerge from '@/base/js/Deepmerge.js';
import { store } from '@/plugins/store';

export const checkUserRight = (userRights, askedRight, askedRestrictions = []) => {
  let right = userRights[askedRight];
  if (right != null && right.allowed === true) {
    let hasRight = true;
    for (let restriction of askedRestrictions) {
      let restrictedRight = false;
      if (right.restrictions == null || right.restrictions[restriction.key] == null) {
        restrictedRight = true;
      } else if (Array.isArray(restriction.value)) {
        let linkType = restriction.linkType || 'AND';
        if (linkType === 'OR') {
          restrictedRight = (restriction.value.some((e) => right.restrictions[restriction.key].includes(e)));
        } else {
          restrictedRight = (restriction.value.every((e) => right.restrictions[restriction.key].includes(e)));
        }
      } else {
        restrictedRight = (right.restrictions[restriction.key].includes(restriction.value));
      }
      hasRight = hasRight && restrictedRight;
    }
    return hasRight;
  }
  return false;
};

export const orgSettingsTemplate = Object.freeze({
  shortname: '',
  parent: '',
  parentUid: '',
  ownPortal: false,
  portalUrl: 'https://admin.euroconsultant.cloud',
  ownDefaultContact: false,
  defaultContact: 'info@euroconsultant.cloud',
  theme: {
    logo: '',
    themeKey: '',
    state: 'inheritFromParent'
  },
  moduleSettings: 'inheritFromParent',
  subOrgSettings: {
    createSubOrgs: false,
    createSubOrgsRecursive: false,
    setModules: false
  },
  countrySettings: {
    show: false,
    countryKey: 'de'
  }
});

export const countrySettings = Object.freeze([
  { key: 'de', name: 'Deutschland', currency: '€', currencyCent: 'ct', vat: 19.0 },
  { key: 'ch', name: 'Schweiz', currency: 'CHF', currencyCent: 'Rp', vat: 8.1 }
]);

export const loadOrgSettings = async (orgUid) => {
  let ioData = {
    targetKey: orgUid,
    context: 'orgSettings'
  };
  let processData = await Vue.prototype.$restClient.callProcess('base', 'globalPropertiesGet', ioData);
  if (processData.processState.state === 'success') {
    return completeSettings(orgSettingsTemplate, processData.ioData.globalProps);
  } else {
    Vue.prototype.$globals.Info.open(processData.processState.messages);
  }
};

export const completeSettings = (templateSettings, loadSettings) => {
  return deepmerge(templateSettings, (loadSettings || {}));
};

export const loadUserSettings = async (moduleName) => {
  let ioData = {
    targetKey: store.state.base.org.uid,
    contextKey: store.state.base.user.uid,
    context: moduleName + 'UserSettings'
  };
  let processData = await Vue.prototype.$restClient.callProcess('base', 'globalPropertiesGet', ioData);
  if (processData.processState.state === 'success') {
    return completeSettings(store.state[moduleName].userSettings, processData.ioData.globalProps);
  } else {
    Vue.prototype.$globals.Info.open(processData.processState.messages);
  }
};

export const saveUserSettings = async (moduleName) => {
  let ioData = {
    targetKey: store.state.base.org.uid,
    contextKey: store.state.base.user.uid,
    context: moduleName + 'UserSettings',
    globalProps: store.state[moduleName].userSettings || {}
  };

  let processData = await Vue.prototype.$restClient.callProcess('base', 'globalPropertiesCreate', ioData);

  if (processData.processState.state === 'success') {
    return completeSettings(processData.ioData.globalProps);
  } else {
    Vue.prototype.$globals.Info.open(processData.processState.messages);
  }
};
